<template>
  <b-container fluid class="overflow-auto">
    <!-- Campaign Interface controls -->
    <h5 class="my-3"><strong>CAMPAIGNS</strong></h5>
    <a-row>
      <a-checkbox v-model="showActive">
        Show only active
      </a-checkbox>
    </a-row>
    <b-row>
      <b-col md="6" class="my-1">
        <b-form-group label-cols-sm="2" label="Per page:" class="mb-0">
          <perPageSelect v-model="perPage" :storageKey="'xml-campaigns'" :pageOptions="pageOptions" />
          <!-- <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select> -->
          <CreateButton module="xml" target="campaign" />
        </b-form-group>
      </b-col>
      <!-- <b-col class="my-1" md="6">
          <b-form-group class="mb-0" label="Per page:" label-cols-sm="2">
            <perPageSelect v-model="perPage" :storageKey="'xml-campaigns'" :pageOptions="pageOptions" />
            <b-form-select v-model="perPage" :options="pageOptions" class="form-control ml-0 w-25"></b-form-select>
            <columnSelect :storageKey="'xml-campaigns-columns'" :options="fields"></columnSelect>
            <CreateButton module="xml" target="campaign" />
          </b-form-group>
        </b-col> -->

      <b-col class="my-1 d-flex flex-row-reverse" md="6">
        <b-form-group class="mb-0">
          <b-input-group>
            <b-form-input v-model="filter" class="form-control w-50" placeholder="Type to Search"></b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Main table element -->
    <b-table :small="true" :bordered="true" :striped="true" :hover="true" show-empty stacked="md" :items="showCampaigns"
      :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter" :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc" :sort-direction="sortDirection" :tbody-tr-class="rowClass" @filtered="onFiltered">
      <!-- ads -->
      <template #cell(ads)="data">
        <div v-if="data.item.AdsCount > 1">
          <!-- <b-link :to="'campaign/' + data.item.Id + '/ads'" @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">{{ data.item.AdsCount }} ads</b-link> -->
          <b-link :to="redirectTo('xml-campaign-ads', { id: data.item.Id })"
            @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">{{ data.item.AdsCount }}
            ads</b-link>
        </div>
        <div v-else-if="data.item.AdsCount === 1">
          <!-- <b-link :to="'campaign/' + data.item.Id + '/ads'" @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">{{ data.item.AdsCount }} ad</b-link> -->
          <b-link :to="redirectTo('xml-campaign-ads', { id: data.item.Id })"
            @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">{{ data.item.AdsCount }} ad
          </b-link>
        </div>
        <div v-else-if="data.item.Status === 0">
          <b-link :to="redirectTo('xml-ad-create-id', { campaignId: data.item.Id })"
            @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">Create ad</b-link>
          <!-- <b-link  :to="$router.resolve({ name: 'xml-ad-create-id', params: { campaignId: data.item.Id } }).resolved.path" @click="showAds(data.item, data.item.AdsCount)" style="color:rgb(105, 151, 244)">Create ad</b-link> -->
        </div>
        <div v-else>
        </div>
      </template>
      <template #cell(StatusString)="data">
        <a-checkbox :checked="(data.item.Status == 0)" @change="toggleCampaignStatus(data.item)">
        </a-checkbox>
      </template>
      <template #cell(Id)="data">
        <div class="items-center">
          {{ data.item.Id }}
        </div>
      </template>
      <template #cell(Description)="data">
        <div class="items-center">
          {{ data.item.Description }}
        </div>
      </template>
      <template #cell(Name)="data">
        <!-- SM screen -->
        <div class="d-block d-sm-none">
          <!-- name slot -->
          <div class="items-center">
            <div v-if="data.item.Name.length <= 20">{{ data.item.Name }}</div>
            <div class="text-left" style="max-width: 200px; min-width: 140px; word-wrap: break-word;"
              v-if="data.item.Name.length > 20">{{ data.item.Name }}</div>
          </div>
        </div>
        <!-- * EXCEPT SM screen -->
        <div class="d-none d-sm-block">
          <!-- name slot -->
          <div class="items-center">
            <div data-toggle="tooltip" data-placement="top" :title="data.item.Name" class="">{{ data.item.Name }}</div>
          </div>
        </div>
      </template>
      <template #cell(actions)="data">
        <router-link class="add-button" @click.stop :to="{ name: 'xml-campaign-update', params: { id: data.item.Id }  }">
          <b-button size="sm" class="btn-info mr-1" @click="editCampaign(data.item.Id)" title="Edit">
            <i class="fa fa-edit"></i>
          </b-button>
        </router-link>
        <b-button size="sm" class="btn-info mr-1" @click="copyCampaign(data.item.Id, data.item)" title="Copy">
          <i class="fa fa-copy"></i>
        </b-button>
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <p>Showing {{ ((currentPage - 1) * perPage).toLocaleString() }} to {{
            (((currentPage - 1) * perPage) + perPage > totalRows ? totalRows : ((currentPage - 1) * perPage) + perPage).toLocaleString()
        }} of {{ parseInt(totalRows).toLocaleString() }} entries</p>
      </b-col>
      <b-col md="6" class="my-1 d-flex flex-row-reverse">
        <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" class="my-0"></b-pagination>
      </b-col>
    </b-row>
    <a-modal v-model="modalVisible" :closable=false>
      <template slot="footer">
        <a-button key="back" @click="handleCancel">
          Cancel
        </a-button>
        <a-button key="submit1" type="primary" @click="handleYes(0)">
          OK
        </a-button>
        <!-- <a-button key="submit2" type="primary" @click="handleYes(1)">
          OK, with Ads
        </a-button> -->
      </template>
      <div class="ant-notification-notice-with-icon"><i aria-label="icon: exclamation-circle-o"
          class="anticon anticon-exclamation-circle-o ant-notification-notice-icon ant-notification-notice-icon-warning">
          <svg viewBox="64 64 896 896" data-icon="question-circle" width="22px" height="22px" fill="currentColor"
            aria-hidden="true" focusable="false">
            <path
              d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z">
            </path>
            <path
              d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0 1 30.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1 0 80 0 40 40 0 1 0-80 0z">
            </path>
          </svg>
        </i>
        <div class="ant-notification-notice-message"> Are you sure you want to clone campaign?
          <!-- <br> -->
          <br>
          <p style="font-size: 14px; padding-top: 5px;"> Clone campaign: <strong>{{ clonedCampaign.Name }}</strong>? </p>
        </div>
      </div>
      <div class="check-box-element">
        <p class="check-box-title">Choose elements to clone with campaign:</p>
        <div class="check-box">
          <a-checkbox v-model="withAds">Ads</a-checkbox>
        </div>
      </div>
    </a-modal>
  </b-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { Modal } from 'ant-design-vue'
import { cloneCampaign } from '@/api/xml/campaign'
import perPageSelect from '@/components/custom/PerPageSelect'

export default {
  components: {
    CreateButton: () => import('@/components/custom/create-button/index.vue'),
    perPageSelect,
  },
  data() {
    return {
      modalVisible: false,
      fields: [
        {
          key: 'actions',
          label: 'Actions',
          class: 'text-center',
          thStyle: 'width:90px',
        },
        {
          key: 'Id',
          label: 'ID',
          headerTitle: 'ID',
          sortable: true,
          thStyle: 'width:50px',
          tdClass: 'text-right',
        },
        {
          key: 'Name',
          label: 'Name',
          sortable: true,
        },
        {
          key: 'Description',
          label: 'Description',
          sortable: true,
        },
        {
          key: 'ads',
          label: 'Ads',
          headerTitle: 'Ads',
          sortable: false,
          show: true,
          class: 'text-center',
          thStyle: 'width:110px',
        },
        {
          key: 'ClicksDailyLimit',
          label: 'Clicks Daily Limit',
          show: true,
          headerTitle: 'Clicks Daily Limit',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          formatter: '$formatNumber',
          thStyle: 'width:140px',
          summed: false,
        },
        {
          key: 'ClicksLimitPerIp',
          label: 'Daily Clicks per IP',
          headerTitle: 'Daily Clicks per IP',
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-right',
          thStyle: 'width:150px',
          formatter: '$formatNumber',
          summed: false,
        },
        {
          key: 'StatusString',
          label: 'Active',
          class: 'text-center',
          thStyle: 'width:80px',
        },
      ],
      withAds: true,
      currentPage: 1,
      perPage: 50,
      pageOptions: [20, 50, 100, 200],
      sortBy: null,
      sortDesc: false,
      sortDirection: 'desc',
      filter: null,
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      clonedCampaign: { Name: '' },
    }
  },
  computed: {
    ...mapState(['campaign', 'settings']),
    ...mapGetters('campaign', ['getCampaigns', 'getActiveCampaigns']),
    showActive: {
      get: function () {
        return this.settings.showActiveCampaigns
      },
      set: function (value) {
        this.$store.commit('CHANGE_SETTING', { setting: 'showActiveCampaigns', value })
      },
    },
    showCampaigns() {
      return this.showActive ? this.getActiveCampaigns : this.getCampaigns
    },
    totalRows() {
      return this.showCampaigns.length
    },
  },
  methods: {
    showAds(campaign, adsCount) {
      if (adsCount > 0) {
        // this.$router.push('/xml/campaign/' + campaign.Id + '/ads/')
        this.$router.push({ name: 'xml-campaign-ads', params: { id: campaign.Id } }).catch(() => { })
      } else {
        // this.$router.push('/xml/ad/create/' + campaign.Id)
        this.$router.push({ name: 'xml-ad-create-id', params: { campaignId: campaign.Id } }).catch(() => { })
      }
    },
    convertValue(val) {
      if (val) {
        return 1
      } else {
        return 0
      }
    },
    redirectTo(route, data = null) {
      return this.$router.resolve({ name: route, params: data }).resolved.path
    },
    handleYes(withAds) {
      this.modalVisible = false
      cloneCampaign(this.clonedCampaign.Id, this.convertValue(this.withAds)).then(response => {
        if (response) {
          // this.$router.push('/xml/campaign/update/' + response.Campaign.Id)
          this.$router.push({ name: 'xml-campaign-update', params: { id: response.Campaign.Id } }).catch(() => { })
          this.$notification.success({
            message: 'Campaign cloned',
            description: `Campaign: ${response.Campaign.Name} has been successfully cloned.`,
          })
        }
      }).catch(error => console.log(error))
    },
    handleCancel() {
      this.modalVisible = false
    },
    copyCampaign(id, campaign) {
      this.modalVisible = true
      this.clonedCampaign = campaign
    },
    rowClass(item, type) {
      if (item != null && item.Status === 1) return 'text-muted'
    },
    editCampaign(id) {
      // this.$router.push('/xml/campaign/update/' + id)
      this.$router.push({ name: 'xml-campaign-update', params: { id: id } }).catch(() => { })
    },
    toggleCampaignStatus(campaign) {
      const status = campaign.Status === 0 ? 'Deactivate' : 'Activate'
      Modal.confirm({
        title: 'Are you sure you want to change campaign status?',
        content: h => <div>{status} campaign <strong>{campaign.Name}</strong>?</div>,
        onOk: function () {
          const newStatus = campaign.Status === 0 ? 1 : 0
          this.$store.dispatch('campaign/CHANGE_STATUS', {
            id: campaign.Id,
            status: newStatus,
          })
        }.bind(this),
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
  created() {
    this.$store.dispatch('campaign/LOAD_CAMPAIGNS')
  },
}
</script>

<style>
[data-kit-theme="default"] .ant-modal-content {
  max-width: 450px;
}

[data-kit-theme="default"] .ant-modal-footer {
  border: none;
  padding-right: 2rem;
  padding-bottom: 2rem;
}

[data-kit-theme="default"] .ant-modal-body {
  padding-top: 32px;
  padding-bottom: 0px;
}

.check-box {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding-left: 1rem;
  /* padding-bottom: 15px; */
}

.check-box-title {
  display: flex;
  justify-content: flex-start;
}

.check-box-element {
  padding-left: 3.2rem;
}
</style>
